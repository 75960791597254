import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./contact.css";
import { Button } from "../Button/Button";

function ContactLanding({ contact }) {

    return (
        <section className="contactLandingPgae serviceLandingPage">
            <div className="wrapper">
                <Container fluid className="p-0">
                    <Row className="justify-content-between align-items-center">
                        <Col lg={5}>
                            <div className="homeContentInner">
                                {contact?.items?.map((item, index) => (
                                    <div key={index}>
                                        {documentToReactComponents(item.fields.heading)}
                                    </div>
                                ))}

                                <div className="contactInformation contactaddres">
                                    <h5>India Office</h5>

                                    {contact?.items?.map((item, index) => (
                                        <a href={item.fields.indiaOfficeUrl} target="_blank" rel="noopener noreferrer">
                                            {item.fields.indiaOfficeAddress}
                                        </a>
                                    ))}
                                </div>

                                <div className="contactInformation contactaddres">
                                    <h5>US Office</h5>

                                    {contact?.items?.map((item, index) => (
                                        <a href={item.fields.usOfficeUrl} target="_blank" rel="noopener noreferrer">
                                            {item.fields.usOfficeAddress}
                                        </a>
                                    ))}
                                </div>
                                <div className="contactInformation">
                                    <h5>More Information</h5>
                                    
                                    {contact?.items?.map((item, index) => (
                                        <div key={index}>
                                            {documentToReactComponents(item.fields.moreInformation)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className="contactFormBlock">
                            {contact?.items?.map((item, index) => (
                                <div key={index}>
                                    {documentToReactComponents(item.fields.heading)}
                                </div>
                            ))}
                            
                            <div className="contactFormBox">
                            <iframe
                                id="JotFormIFrame-243622431069453"
                                title="EWS Form"
                                onLoad={() => window.parent.scrollTo(0, 0)}
                                allowtransparency="true"
                                allow="geolocation; microphone; camera; fullscreen"
                                src="https://form.jotform.com/243622431069453"
                                frameBorder="0"
                                style={{
                                minWidth: "100%",
                                maxWidth: "100%",
                                height: "710px",
                                border: "none",
                                }} // Adjust height here
                                scrolling="yes" // Enable scrolling
                            ></iframe>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{ display: "none" }}>
                <Button></Button>
            </div>
        </section>
    );
}

export default ContactLanding;
