import React, { useEffect } from "react";
import thankicon from "../assets/thankyou/thank-you.svg";

function ThankyouPage(props) {
  useEffect(() => {
    document.title = props.metaThankyou;
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="content-wrapper thank-you-wrapper">
        <div className="wrapper">
          <div className="thank-you-block">
            <img src={thankicon} className="img-size" alt="box"/>
            <h1>Thanks for submitting!</h1>
            <p>your message has been sent!</p>
            <a
              className="btn1 btn-sm undefined btn--primary btn--medium"
              href="/"
            >
              <span>Go Home</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThankyouPage;
