import React, { useEffect, useState } from "react";
import useContentful from "../useContentful";
import ContactLanding from "../components/Contact/ContactLanding";

function ContactPage(props) {
    const [contact, setContact] = useState([]);
    const { getContact } = useContentful();

    useEffect(() => {
        document.title = props.metaContact
        getContact().then((res) => {
            setContact(res)
        });
        // eslint-disable-next-line
    }, [])

    
    return (
        <>
            <div className="content-wrapper contactContentWrapper">
                <ContactLanding contact={contact} />
            </div>
        </>
    );
}

export default ContactPage;
