import React, { useEffect } from "react";

const NotFound = (props) => {
  useEffect(() => {
    document.title = props.metaNotFound;
    // eslint-disable-next-line
  }, []);
  return (
    <div
      style={{ textAlign: "center", padding: "50px", margin: " 200px auto" }}
    >
      <h1>404</h1>
      <p>Oops! The page you're looking for does not exist.</p>
      <a href="/" style={{ color: "blue", textDecoration: "underline" }}>
        Go Home
      </a>
    </div>
  );
};

export default NotFound;
